import React from 'react';

function Footer() {
    return (
        <footer className='footer'>
            Copyright © Csaba Vadasz - Csaba79-coder 2023
        </footer>
    );
}

export default Footer;